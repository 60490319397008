// const pre=`http://localhost:2995/`;
const pre=`http://5.189.166.187:5551/GameTriviaGamesBackend/`;

let redirectApi=`${pre}RedirectMtnBigcash`;
export{redirectApi};

let getTermsApi=`${pre}sendTerms`;
export{getTermsApi};

let gameIdUniversal=`11`;
export{gameIdUniversal};